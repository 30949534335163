import * as React from "react"

function SvgFax(props) {
  return (
    <svg
      aria-hidden="true"
      data-prefix="fas"
      data-icon="fax"
      className="fax_svg__svg-inline--fa fax_svg__fa-fax fax_svg__fa-w-16"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="currentColor"
        d="M480 160V77.25a32 32 0 00-9.38-22.63L425.37 9.37A32 32 0 00402.75 0H160a32 32 0 00-32 32v448a32 32 0 0032 32h320a32 32 0 0032-32V192a32 32 0 00-32-32zM288 432a16 16 0 01-16 16h-32a16 16 0 01-16-16v-32a16 16 0 0116-16h32a16 16 0 0116 16zm0-128a16 16 0 01-16 16h-32a16 16 0 01-16-16v-32a16 16 0 0116-16h32a16 16 0 0116 16zm128 128a16 16 0 01-16 16h-32a16 16 0 01-16-16v-32a16 16 0 0116-16h32a16 16 0 0116 16zm0-128a16 16 0 01-16 16h-32a16 16 0 01-16-16v-32a16 16 0 0116-16h32a16 16 0 0116 16zm0-112H192V64h160v48a16 16 0 0016 16h48zM64 128H32a32 32 0 00-32 32v320a32 32 0 0032 32h32a32 32 0 0032-32V160a32 32 0 00-32-32z"
      />
    </svg>
  )
}

export default SvgFax
