import React from "react"
import Container from "../container"
import styles from "./header.module.scss"
import { graphql, Link, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Spin as Hamburger } from "hamburger-react"
import cn from "classnames"
import { Minus, Plus } from "../icons"

export default function Header() {
  const [isOpen, setOpen] = React.useState(false)
  const [subMenu, setSubMenu] = React.useState(false)

  return (
    <StaticQuery
      query={query}
      render={data => (
        <header className={cn(styles.header, isOpen ? styles.open : null)}>
          <Container className={styles.container}>
            <Link to="/" className={styles.logo}>
              <Img
                style={{ width: "inherit", height: "inherit" }}
                fixed={data.logo.sharp.fixed}
                alt="logo"
              />
            </Link>
            <div className={styles.hamburger}>
              <Hamburger
                rounded
                toggled={isOpen}
                toggle={setOpen}
                label="Toggle menu"
                distance="sm"
              />
            </div>
            <nav className={styles.nav}>
              <ul className={styles.menu}>
                <li className={styles.menuItem}>
                  <Link
                    to="/"
                    className={styles.menuLink}
                    activeClassName={styles.active}
                  >
                    Ana Sayfa
                  </Link>
                </li>
                <li className={styles.menuItem}>
                  <Link
                    to="#"
                    onClick={e => setSubMenu(!subMenu)}
                    className={cn(styles.menuLink, styles.hasSub)}
                  >
                    Eğitimler
                    {subMenu ? <Minus /> : <Plus />}
                  </Link>
                  <ul
                    style={{ display: subMenu ? "block" : "none" }}
                    className={styles.subMenu}
                  >
                    <li className={styles.menuItem}>
                      <Link
                        to="/is-guvenligi-uzmanligi-egitimleri"
                        className={styles.subMenuLink}
                        activeClassName={styles.active}
                      >
                        İş Güvenliği Uzmanlığı Eğitimleri
                      </Link>
                    </li>
                    <li className={styles.menuItem}>
                      <Link
                        to="/egiticinin-egitimi"
                        className={styles.subMenuLink}
                        activeClassName={styles.active}
                      >
                        Eğiticinin Eğitimi
                      </Link>
                    </li>
                    <li className={styles.menuItem}>
                      <Link
                        to="/mesleki-yeterlilik-sinav-ve-ozel-egitimleri"
                        className={styles.subMenuLink}
                        activeClassName={styles.active}
                      >
                        Mesleki Yeterlilik Sınav ve Özel Eğitimleri
                      </Link>
                    </li>
                    <li className={styles.menuItem}>
                      <Link
                        to="/yanginla-mucadele-ve-yangin-sondurme-egitimi"
                        className={styles.subMenuLink}
                        activeClassName={styles.active}
                      >
                        Yangınla Mücadele ve Yangın Söndürme Eğitimi
                      </Link>
                    </li>
                    <li className={styles.menuItem}>
                      <Link
                        to="/iso-17024-dokumantasyonu-danismanlik"
                        className={styles.subMenuLink}
                        activeClassName={styles.active}
                      >
                        ISO 17024 Dokümantasyonu ve Danışmanlık
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className={styles.menuItem}>
                  <Link
                    to="/hakkimizda"
                    className={styles.menuLink}
                    activeClassName={styles.active}
                  >
                    Hakkımızda
                  </Link>
                </li>
                <li className={styles.menuItem}>
                  <Link
                    to="/iletisim"
                    className={styles.menuLink}
                    activeClassName={styles.active}
                  >
                    İletişim
                  </Link>
                </li>
              </ul>
            </nav>
          </Container>
        </header>
      )}
    />
  )
}

export const query = graphql`
  query {
    logo: file(relativePath: { eq: "logo.png" }) {
      sharp: childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
