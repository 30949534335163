import * as React from "react"

function SvgPhone(props) {
  return (
    <svg
      aria-hidden="true"
      data-prefix="fas"
      data-icon="phone-alt"
      className="phone_svg__svg-inline--fa phone_svg__fa-phone-alt phone_svg__fa-w-16"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="currentColor"
        d="M497.39 361.8l-112-48a24 24 0 00-28 6.9l-49.6 60.6A370.66 370.66 0 01130.6 204.11l60.6-49.6a23.94 23.94 0 006.9-28l-48-112A24.16 24.16 0 00122.6.61l-104 24A24 24 0 000 48c0 256.5 207.9 464 464 464a24 24 0 0023.4-18.6l24-104a24.29 24.29 0 00-14.01-27.6z"
      />
    </svg>
  )
}

export default SvgPhone
