import React from "react"
import Container from "../container"
import { Phone } from "../icons"
import SocialIcons from "../social-icons"
import styles from "./top-bar.module.scss"

export default function TopBar() {
  return (
    <div className={styles.topBar}>
      <Container className={styles.container}>
        <div className={styles.phone}>
          <Phone />
          <span>0212 871 1060</span>
        </div>
        <SocialIcons />
      </Container>
    </div>
  )
}
