import { Link } from "gatsby"
import React from "react"
import Container from "../container"
import { Envelope, Fax, MapMarker, Phone } from "../icons"
import SocialIcons from "../social-icons"
import Title from "../title"
import styles from "./footer.module.scss"

export default function Footer() {
  return (
    <>
      <footer className={styles.footer}>
        <Container className={styles.container}>
          <div>
            <Title headingLevel="h3">AFFA & İKÜ</Title>
            <p>
              İstanbul Kültür Üniversitesi Sürekli Eğitim Merkezi ile birlikte
              geliştirdiğimiz projemizde akademik bilgi ve saha tecrübelerimizi
              birleştirerek üst düzey bir eğitim sunuyoruz.
            </p>
          </div>
          <div>
            <Title headingLevel="h3">Eğitimler</Title>
            <ul className={styles.menuWidget}>
              <li>
                <Link to="/is-guvenligi-uzmanligi-egitimleri">
                  İş Güvenliği Uzmanlığı Eğitimleri
                </Link>
              </li>
              <li>
                <Link to="/egiticinin-egitimi">Eğiticinin Eğitimi</Link>
              </li>
              <li>
                <Link to="/mesleki-yeterlilik-sinav-ve-ozel-egitimleri">
                  Mesleki Yeterlilik Sınav ve Özel Eğitimleri
                </Link>
              </li>
              <li>
                <Link to="/yanginla-mucadele-ve-yangin-sondurme-egitimi">
                  Yangınla Mücadele ve Yangın Söndürme Eğitimi
                </Link>
              </li>
              <li>
                <Link to="/iso-17024-dokumantasyonu-danismanlik">
                  ISO 17024 Dokümantasyonu ve Danışmanlık
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <Title headingLevel="h3">İletişim Bİlgileri</Title>
            <ul className={styles.contactWidget}>
              <li>
                <MapMarker />
                <span>
                  Cumhuriyet Mah. Uludağ Sk. No:15 D: 3 Beykent
                  Büyükçekmece/İstanbul
                </span>
              </li>
              <li>
                <Phone />
                <span>
                  0212 871 1060
                  <br />
                  0850 495 5606
                </span>
              </li>
              <li>
                <Fax />
                <span>0212 870 1061</span>
              </li>
              <li>
                <Envelope />
                <a href="mailto:info@affakalite.com.tr">
                  info@affakalite.com.tr
                </a>
              </li>
            </ul>
          </div>
        </Container>
      </footer>
      <div className={styles.copyright}>
        <Container className={styles.copyrightContainer}>
          <span>
            Copyright {new Date().getFullYear()} affakalite.com.tr | Tüm hakları
            saklıdır.
          </span>
          <SocialIcons />
        </Container>
      </div>
    </>
  )
}
