import React from "react"
import styles from "./seperator.module.scss"

export default function Seperator() {
  return (
    <div className={styles.seperator}>
      <span></span>
    </div>
  )
}
